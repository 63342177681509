<script lang="ts" setup>
import type { VTextField } from 'vuetify/components'

const { t } = useI18n()
const { isEmail, isString } = useValidation()

// TODO: Infer props from Vuetify base component
const props = withDefaults(
  defineProps<{
    type?: 'text' | 'password' | 'email'
    hint?: string
    placeholder?: string
    label?: string
    autofocus?: boolean
    readonly?: boolean
    hideDetails?: boolean
    disabled?: boolean
    required?: boolean
    persistentHint?: boolean
    rules?: ((value: string) => boolean | string)[]
    showRequiredFieldMessage?: boolean
  }>(),
  {
    type: 'text',
    rules: () => [],
  }
)

const emit = defineEmits<{
  (e: 'enter' | 'focus' | 'blur', event: InputEvent): void
  (e: 'update:valid', isValid: boolean, revalidate?: () => Promise<string[]>): void
}>()

const fieldInstance = ref<InstanceType<typeof VTextField> | null>(null)

watchEffect(() => {
  emit('update:valid', Boolean(fieldInstance.value?.isValid), fieldInstance.value?.validate)
})

const modelValue = defineModel<string>({ required: true })

const showValue = ref(props.type !== 'password')

const defaultRules = computed(() => {
  if (props.required) {
    return props.showRequiredFieldMessage
      ? [value => isString(value) || t('global.field_required', { label: props.label })]
      : [isString]
  } else if (props.type === 'password') {
    return [isString]
  } else if (props.type === 'email') {
    return [value => isEmail(value) || t('global.email_invalid')]
  }
  return []
})

const inputRules = computed(() => [...defaultRules.value, ...props.rules])

const options = computed(() => {
  if (props.type === 'email') {
    return { autocomplete: 'email', appendInnerIcon: 'custom:email' }
  } else if (props.type === 'password') {
    return {
      autocomplete: 'current-password',
      appendInnerIcon: showValue.value ? 'custom:visibility' : 'custom:visibility_off',
    }
  }
  return {}
})

const onIconClick = () => {
  if (props.type !== 'password') return
  showValue.value = !showValue.value
}
</script>

<template>
  <v-text-field
    ref="fieldInstance"
    v-model="modelValue"
    :autofocus="autofocus"
    :readonly="readonly"
    :disabled="disabled"
    :hint="hint"
    :placeholder="placeholder"
    :label="label"
    :rules="inputRules"
    :type="showValue ? 'text' : 'password'"
    :hide-details="hideDetails"
    :persistent-hint="persistentHint"
    v-bind="options"
    variant="filled"
    @focus="emit('focus', $event)"
    @blur="emit('blur', $event)"
    @keyup.enter="emit('enter', $event)"
    @click:append-inner="onIconClick"
  />
</template>
